export default {
  coffees: [
    {
      id: '001',
      korName: '카페 아메리카노',
      engName: 'café americano',
      imageUri: require('../../assets/images/menu/beverage/coffee/001.png'),
      price: 4900,
      desc: '에스프레소에 뜨거운 물을 부은 커피로 깊은 맛과 향이 특징',
    },
    {
      id: '002',
      korName: '카페라떼',
      engName: 'café latte',
      imageUri: require('../../assets/images/menu/beverage/coffee/002.png'),
      price: 5400,
      desc: '에스프레소에 신선한 스팀밀크와 부드러운 우유거품을 살짝 올린 커피',
    },
    {
      id: '003',
      korName: '카푸치노',
      engName: 'cappuccino',
      imageUri: require('../../assets/images/menu/beverage/coffee/003.png'),
      price: 5400,
      desc: '에스프레소에 부드러운 우유거품을 가득 올린 커피',
    },
    {
      id: '004',
      korName: '캐러멜 마끼아또',
      engName: 'caramel macchiato',
      imageUri: require('../../assets/images/menu/beverage/coffee/004.png'),
      price: 6300,
      desc: '에스프레소와 신선한 우유에 카라멜을 드리즐하여 달콤한 카라멜향이 특징인 커피',
    },
    {
      id: '005',
      korName: '바닐라라떼',
      engName: 'vanilla latte',
      imageUri: require('../../assets/images/menu/beverage/coffee/005.png'),
      price: 5900,
      desc: '에스프레소와 신선한 우유에 바닐라 시럽을 더해 달콤한 맛과 향이 특징인 커피',
    },
    {
      id: '007',
      korName: '스패니쉬라떼',
      engName: 'spanish latte',
      imageUri: require('../../assets/images/menu/beverage/coffee/007.png'),
      price: 5900,
      desc: '에스프레소 샷과 연유 그리고 우유의 조화로움이 특징인 음료',
    },
    {
      id: '008',
      korName: '플랫화이트',
      engName: 'flat white',
      imageUri: require('../../assets/images/menu/beverage/coffee/008.png'),
      price: 5400,
      desc: '아티제 스페셜티 커피 고유의 깊고 진한 맛과 우유가 조화로운 커피',
    },
  ],
  realFruits: [
    {
      id: '001',
      korName: '리얼후르츠 오렌지주스',
      engName: 'real fruit orange juice',
      imageUri: require('../../assets/images/menu/beverage/fruit/001.png'),
      price: 8300,
      desc: '신선한 오렌지의 생과즙 100%로 만들어 과일본연의 단맛과 신선함을 느낄 수 있는 주스',
    },
    {
      id: '002',
      korName: '리얼후르츠 자몽주스',
      engName: 'real fruit grapefruit juice',
      imageUri: require('../../assets/images/menu/beverage/fruit/002.png'),
      price: 8300,
      desc: '자몽의 생과즙 100%로 비타민과 건강함을 함께 챙길 수 있는 음료',
    },
    {
      id: '004',
      korName: '리얼후르츠 토마토주스',
      engName: 'real fruit tomato juice',
      imageUri: require('../../assets/images/menu/beverage/fruit/004.png'),
      price: 8300,
      desc: '신선한 토마토 100%의 생과일주스로 신선함을 느낄 수 있는 주스',
    },
    {
      id: '005',
      korName: '로열자몽티',
      engName: 'real royal grapefruit tea',
      imageUri: require('../../assets/images/menu/beverage/fruit/005.png'),
      price: 6700,
      desc: '신선한 자몽즙과 은은한 얼그레이티의 향이 잘 어우러진 음료',
    },
    {
      id: '006',
      korName: '클래식 레몬 허니 티',
      engName: 'real classic lemon honey tea',
      imageUri: require('../../assets/images/menu/beverage/fruit/006.png'),
      price: 6700,
      desc: '직접짠 레몬즙에 국내산 꿀을 넣어 비타민C가 풍부한 음료',
    },
    {
      id: '007',
      korName: '시그니처 오렌지 에이드',
      engName: 'signature orange ade',
      imageUri: require('../../assets/images/menu/beverage/fruit/007.png'),
      price: 6700,
      desc: '오렌지주스에 청량한 탄산수를 더해 오렌지의 달콤함을 느낄 수 있는 에이드',
    },
    {
      id: '008',
      korName: '시그니처 레몬 에이드',
      engName: 'signature lemon ade',
      imageUri: require('../../assets/images/menu/beverage/fruit/008.png'),
      price: 6700,
      desc: '레몬주스에 청량한 탄산수를 더해 상큼하면서 통통 튀는 에이드',
    },
    {
      id: '009',
      korName: '시그니처 자몽 에이드',
      engName: 'signature grapefruit ade',
      imageUri: require('../../assets/images/menu/beverage/fruit/009.png'),
      price: 6700,
      desc: '자몽주스에 청량한 탄산수를 더해 상큼 달콤하게 즐기는 에이드',
    },
  ],
  teas: [
    {
      id: '001',
      korName: '그린티',
      engName: 'green tea',
      imageUri: require('../../assets/images/menu/beverage/tea/001.png'),
      price: 5800,
      desc: '녹색 식물의 은근한 향과 입안을 감도는 상쾌한 뒷 맛을 위해 봄에 수확한 아주 귀한 녹차',
    },
    {
      id: '003',
      korName: '얼그레이',
      engName: 'earl grey',
      imageUri: require('../../assets/images/menu/beverage/tea/003.png'),
      price: 5800,
      desc: '한층 더 고급스러운 얼그레이 찻잎과 버가모트 에센스의 조합으로 왕족이 된 듯한 느낌을 받을 수 있는 홍차',
    },
    {
      id: '005',
      korName: '루이보스',
      engName: 'rooibos',
      imageUri: require('../../assets/images/menu/beverage/tea/005.png'),
      price: 5800,
      desc:
        '화창한 햇빛에 우려낸 아프리카의 전설적인 붉은 차. 살짝 담콤하면서 미디엄 바디의 딱 마시기 적당한 루이보스 차 (카페인프리)',
    },
    {
      id: '006',
      korName: '페퍼민트',
      engName: 'peppermint',
      imageUri: require('../../assets/images/menu/beverage/tea/006.png'),
      price: 5800,
      desc: '진한 초콜렛 향과 강렬한 뒷맛이 있는 풍부하고 부드러운 풍미의 페퍼민트 차 (카페인프리)',
    },
    {
      id: '007',
      korName: '카모마일',
      engName: 'chamomile',
      imageUri: require('../../assets/images/menu/beverage/tea/007.png'),
      price: 5800,
      desc:
        '갓 채집한 신선하고 풍성한 꽃다발의 금빛 카모마일로 맛있게 자극적이면서도 동시에 나른한 편안한 차 (카페인프리)',
    },
  ],
  others: [
    {
      id: '001',
      korName: '데일리 요거트 스무디',
      engName: 'daily yogurt smoothie',
      imageUri: require('../../assets/images/menu/beverage/others/001.png'),
      price: 6600,
      desc: '요거트의 새콤달콤한 맛을 느낄 수 있는 스무디',
    },
    {
      id: '002',
      korName: '레몬 요거트 스무디',
      engName: 'lemon yogurt smoothie',
      imageUri: require('../../assets/images/menu/beverage/others/002.png'),
      price: 6900,
      desc: '요거트의 부드러움과 생레몬의 과즙이 첨가되어 더욱 상큼한 맛의 스무디',
    },
    {
      id: '005',
      korName: '발로나 초코 프라페',
      engName: 'VALRHONA choco frappe',
      imageUri: require('../../assets/images/menu/beverage/others/005.png'),
      price: 6800,
      desc: '진한 발로나 초콜릿이 들어간 깊은 맛의 초코 프라페',
    },
    {
      id: '018',
      korName: '발로나 더블 초콜릿',
      engName: 'VALRHONA double chocolate',
      imageUri: require('../../assets/images/menu/beverage/others/018.png'),
      price: 6500,
      desc: '발로나 다크&밀크 초콜릿을 매장에서 직접 블렌딩한 초콜릿 음료',
    },
    {
      id: '012',
      korName: '애플밀크티',
      engName: 'apple milk tea',
      imageUri: require('../../assets/images/menu/beverage/others/012.png'),
      price: 6200,
      desc: '사과의 은은한 향과 우유의 부드러움이 잘 어울리는 음료',
    },
    {
      id: '013',
      korName: '잉글리쉬 티 라떼',
      engName: 'english tea latte',
      imageUri: require('../../assets/images/menu/beverage/others/013.png'),
      price: 6200,
      desc: '향긋한 홍차 파우더에 고소한 우유가 더해진 음료',
    },
    {
      id: '014',
      korName: '제주 말차 라떼',
      engName: 'Jeju Matcha latte',
      imageUri: require('../../assets/images/menu/beverage/others/014.png'),
      price: 6200,
      desc: '녹차의 은은한 향과 고소한 우유가 잘 어우러진 음료',
    },
    {
      id: '017',
      korName: '발로나 자바칩 프라페',
      engName: 'VALRHONA Java Chip Frappe',
      imageUri: require('../../assets/images/menu/beverage/others/017.png'),
      price: 6200,
      desc: '에스프레소와 발로나 초콜릿칩이 만나 깊은 초콜릿 풍미를 느낄 수 있는 모카 프라페',
    },
  ],
};
